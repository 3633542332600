import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from '../css/NeonGrid.module.css';

export default function ImagesGrid({ images }) {
  if (images.length === 0) {
    return null; 
  }

  return (
    <ImageList sx={{ width: 600, height: 550, borderRadius: 10 }} cols={2} rowHeight={190}>
      {images.map((img, index) => (
        <ImageListItem key={index}>
          <img
            className={styles.grid}
            src={img}
            alt={`Uploaded ${index + 1}`} 
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
