import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import ReachUs from './pages/ReachUS';

function App() {
  return (
    <Router>
      
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/reach-us" element={<ReachUs />} />
        </Routes>

    </Router>
  );
}

export default App;
