import React from 'react';
import styles from '../css/Header.module.css';
import { Container, Grid, Box } from "@mui/material";
import Form from '../components/Form';
function ReachUs() {

  return (

      <Container>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ maxWidth: '1200px', marginTop: 1 }}
        >
          <Grid item xs={12} textAlign="center">
            <Box sx={{ marginTop: 8 }}>
              <button className={styles.title}>
                Faith Byte Nexus - Visual Search
              </button>
            </Box>
          </Grid>

          <Grid 
            item 
            xs={12} 
            sm={12} 
            md={12} 
            lg={12} 
            display="flex" 
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            <Form/>
          </Grid>
        </Grid>
      </Container>

  );
}

export default ReachUs;
