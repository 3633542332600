import styles from "../css/Loader.module.css"

export default function Loader(){
    return (
        <>
            <div className={styles.loader}>
            <div className={styles.face}>
                <div className={styles.circle}></div>
            </div>
            <div className={styles.face}>
                <div className={styles.circle}></div>
            </div>
        </div>
        </>
    )
}